<template>
  <v-card class="card-shadow border-radius-xl">
    <common-loading :title="loading_tp.title" :message="loading_tp.message" :loading_dialog="loading_tp.dialog"
      :show_loading="loading_tp.show_loading" :show_confirm="loading_tp.show_confirm"
      v-on:confirm="loading_tp.dialog = false"></common-loading>
    <transcript-dialog :show.sync="show_tp" :loading="loading_tp.dialog" :tpData="tp"
      :lbtype="lbtype"></transcript-dialog>
    <common-loading :title="loading.title" :message="loading.message" :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading" :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"></common-loading>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">
        {{ zone_name }}
      </h5>
      <v-spacer></v-spacer>
      <v-btn :ripple="false" elevation="0" color="#fff" class="
          font-weight-bolder
          btn-default
          bg-gradient-secondary
          py-5
          px-4
          mx-2
        " small @click="$router.back()">
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        {{ $t('back') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table :headers="tasks_headers" :items="tasks" :loading="data_loading" hide-default-footer
        :items-per-page="per_page" class="row-height-50">
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getStatusColor(item.status)" text-color="white" small>
            {{ item.status }}
          </v-chip>
        </template>
        <!-- lbkey -->
        <!-- <template v-slot:[`item.lbkey`]="{ item }">
          {{ item.lbkey.slice(-9) }}
        </template> -->
        <template v-slot:[`item.land_mark_data.buildings`]="{ item }">
          {{ getBuildingNumber(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="view(item)">mdi-briefcase-eye-outline</v-icon>
            </template>
            <span>{{ $t("View") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="export_pdf(item.lbkey)">mdi-file-pdf-box</v-icon>
            </template>
            <span>{{ $t("Export") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <v-row>
        <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
          <v-pagination prev-icon="fa fa-angle-left" next-icon="fa fa-angle-right" class="pagination" color="#cb0c9f"
            v-model="zonePage" :length="getPageCount()" circle :total-visible="15"></v-pagination>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import TranscriptBase from "./Widgets/TranscriptBase.vue";
import TranscriptDialog from "./Widgets/TranscriptDialog.vue";
export default {
  name: "DevelopmentTask",

  components: {
    CommonLoading,
    TranscriptDialog,
  },
  mixins: [TranscriptBase],
  data() {
    return {
      base_headers: [
        { text: "地建號", value: "lbkey", sortable: false },
      ],
      tasks: [],
      zoneName: "",
      data_loading: false,
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      tp: {
        "mark": {},
        "o_data": [],
        "c_data": [],
      },
      show_tp: false,
      loading_tp: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      zonePage: 1,
      per_page: 20,
      total: 0,
      watch_page: false,
    };
  },

  computed: {
    zoneId() {
      return this.$route.params.zone_id;
    },
    lbtype() {
      return this.$route.params.lbtype;
    },
    zone_name() {
      return this.$route.params.zone_name;
    },
    tasks_headers() {
      let headers = [...this.base_headers];
      if (this.lbtype == 'L') {
        headers.push({ text: "縣市", value: "land_mark_data.city", sortable: false });
        headers.push({ text: "行政區", value: "land_mark_data.district", sortable: false });
        // region_name
        headers.push({ text: "地段", value: "land_mark_data.region_name", sortable: false });
        // area
        headers.push({ text: "面積", value: "land_mark_data.area", sortable: false });
        // zone
        headers.push({ text: "使用分區", value: "land_mark_data.zone", sortable: false });
        // current_land_price
        // headers.push({ text: "公告土地現值", value: "land_mark_data.current_land_price", sortable: false });
        // current_land_price_date
        // headers.push({ text: "公告土地現值日期", value: "land_mark_data.current_land_price_date", sortable: false });
        //    "公告地價年月": "al_value_date",
        // headers.push({ text: "公告地價", value: "land_mark_data.al_value", sortable: false });
        //    "公告地價年月": "al_value_date",
        // headers.push({ text: "公告地價日期", value: "land_mark_data.al_value_date", sortable: false });
        // buildings
        headers.push({ text: "建物數量", value: "land_mark_data.buildings", sortable: false });
        // info.o_num
        headers.push({ text: "所有權", value: "info.o_num", sortable: false });
        // info.c_num
        headers.push({ text: "他項權", value: "info.c_num", sortable: false });
      } else {
        //door
        headers.push({ text: "門牌", value: "building_mark_data.door", sortable: false });
        // floors
        headers.push({ text: "層數", value: "building_mark_data.floors", sortable: false });
        // purpose
        headers.push({ text: "用途", value: "building_mark_data.purpose", sortable: false });
        // area
        headers.push({ text: "面積", value: "building_mark_data.area", sortable: false });
        // completion_date_str
        headers.push({ text: "完成日期", value: "building_mark_data.completion_date_str", sortable: false });
        // info.o_num
        headers.push({ text: "所有權", value: "info.o_num", sortable: false });
        // info.c_num
        headers.push({ text: "他項權", value: "info.c_num", sortable: false });
      }
      // push actions column
      headers.push({ text: "操作", value: "actions",  sortable: false });
      return headers;
    },
  },

  methods: {
    ...mapGetters(["getToken"]),
    getPageCount() {
      return Math.ceil(this.total / this.per_page);
    },

    getStatusColor(status) {
      const colors = {
        pending: "grey",
        in_progress: "blue",
        completed: "green",
        delayed: "orange",
        cancelled: "red",
      };
      return colors[status] || "grey";
    },

    async fetchTasks() {
      const currentObj = this;
      this.data_loading = true;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/${this.zoneId}/tasks`;
      let config = {
        params: {
          page: this.zonePage,
          size: this.per_page
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      console.log(url, config);

      try {
        const response = await this.axios.get(url, config);
        console.log(response.data);
        this.tasks = response.data.items || [];
        this.total = response.data.total || 0;
      } catch (error) {
        console.error("Error fetching tasks:", error);
        console.error("Error response:", error.response);
      } finally {
        currentObj.data_loading = false;
      }
    },
    getBuildingNumber(item) {
      if (item == null || item.land_mark_data == null || item.land_mark_data.buildings == null) return 0;
      let buildings = item.land_mark_data.buildings;
      return JSON.parse(buildings).length;
    },
    view(item) {

      this.get_transcript_by_key(item.lbkey);
    },
    setViewTranscript(item) {
      this.tp = item;
      this.show_tp = true;
    },
  },

  watch: {
    zonePage(val) {
      console.log('zone page changed', val);
      this.fetchTasks();
      console.log(this.zonePage);
    }
  },

  mounted() {
    this.fetchTasks();
  },
};
</script>

<style scoped>
.row-height-50 ::v-deep tbody tr {
  height: 50px !important;
}
</style>
