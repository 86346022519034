var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('common-loading',{attrs:{"title":_vm.loading_tp.title,"message":_vm.loading_tp.message,"loading_dialog":_vm.loading_tp.dialog,"show_loading":_vm.loading_tp.show_loading,"show_confirm":_vm.loading_tp.show_confirm},on:{"confirm":function($event){_vm.loading_tp.dialog = false}}}),_c('transcript-dialog',{attrs:{"show":_vm.show_tp,"loading":_vm.loading_tp.dialog,"tpData":_vm.tp,"lbtype":_vm.lbtype},on:{"update:show":function($event){_vm.show_tp=$event}}}),_c('common-loading',{attrs:{"title":_vm.loading.title,"message":_vm.loading.message,"loading_dialog":_vm.loading.dialog,"show_loading":_vm.loading.show_loading,"show_confirm":_vm.loading.show_confirm},on:{"confirm":function($event){_vm.loading.dialog = false}}}),_c('v-card-title',{staticClass:"justify-center py-2 px-4"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-grt mb-0"},[_vm._v(" "+_vm._s(_vm.zone_name)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"\n        font-weight-bolder\n        btn-default\n        bg-gradient-secondary\n        py-5\n        px-4\n        mx-2\n      ",attrs:{"ripple":false,"elevation":"0","color":"#fff","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"12"}},[_vm._v("fa fa-power-off")]),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"row-height-50",attrs:{"headers":_vm.tasks_headers,"items":_vm.tasks,"loading":_vm.data_loading,"hide-default-footer":"","items-per-page":_vm.per_page},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.land_mark_data.buildings",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBuildingNumber(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.view(item)}}},on),[_vm._v("mdi-briefcase-eye-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("View")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.export_pdf(item.lbkey)}}},on),[_vm._v("mdi-file-pdf-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Export")))])])]}}],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"12","lg":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#cb0c9f","length":_vm.getPageCount(),"circle":"","total-visible":15},model:{value:(_vm.zonePage),callback:function ($$v) {_vm.zonePage=$$v},expression:"zonePage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }